import * as React from 'react'
import { memo } from 'react'

const SvgGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 19.991 20"
  >
    <path d="M17.671 10.366v9.132H2.326v-9.132M.5 5.318h18.991v4.564H.5zM9.996 19.5V5.807M9.801 5.066H5.692a2.283 2.283 0 1 1 0-4.566c3.196 0 4.109 4.566 4.109 4.566ZM10.365 5.066h4.109a2.283 2.283 0 1 0 0-4.566c-3.196 0-4.109 4.566-4.109 4.566Z" />
  </svg>
)

const Memo = memo(SvgGift)
export default Memo
