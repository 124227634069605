import * as React from 'react'
import { memo } from 'react'
const SvgTelegram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 19.078 16"
  >
    <path d="m19.024 1.457-2.879 13.577c-.217.958-.784 1.2-1.589.745l-4.387-3.233-2.116 2.037a1.1 1.1 0 0 1-.882.43l.315-4.468 8.13-7.347c.353-.315-.077-.49-.549-.175l-10.05 6.33L.689 7.998c-.941-.294-.958-.941.2-1.393L17.814.085c.785-.294 1.466.175 1.21 1.372Z" />
  </svg>
)
const Memo = memo(SvgTelegram)
export default Memo
