import * as React from 'react'
import { memo } from 'react'

const SvgDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16.031 16"
  >
    <path d="M.763 16a.76.76 0 0 1-.753-.677.613.613 0 0 1-.01-.135v-3.549a.758.758 0 0 1 1.293-.539.766.766 0 0 1 .221.535v2.845h13.007v-2.841a.757.757 0 0 1 1.292-.535.8.8 0 0 1 .218.541v3.548a.769.769 0 0 1-.026.2.761.761 0 0 1-.741.611Zm7.167-3.916c-.016 0-.03-.006-.046-.006a.868.868 0 0 1-.489-.247.189.189 0 0 1-.02-.021L3.654 8.091a.889.889 0 0 1 0-1.252.891.891 0 0 1 1.252 0l2.237 2.236V.884A.888.888 0 0 1 8.025 0a.9.9 0 0 1 .625.258.87.87 0 0 1 .258.626v8.191l2.236-2.236a.891.891 0 0 1 1.252 0 .9.9 0 0 1-.016 1.252l-3.72 3.72a.058.058 0 0 1-.021.021.878.878 0 0 1-.488.247c-.016 0-.031.006-.046.006a.714.714 0 0 1-.091 0Z" />
  </svg>
)

const Memo = memo(SvgDownload)
export default Memo
