import * as React from 'react'
import { memo } from 'react'

const SvgLinkedin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20.26 20"
  >
    <path d="M16.148 19.995a.345.345 0 0 1-.066-.026V12.61a3.624 3.624 0 0 0-.365-1.565 2.444 2.444 0 0 0-.436-.632 1.952 1.952 0 0 0-1.189-.594 2.289 2.289 0 0 0-.785.028 2 2 0 0 0-1.123.681 3.259 3.259 0 0 0-.621 1.152 2.2 2.2 0 0 0-.111.7q.006 3.649 0 7.3a.592.592 0 0 1-.021.246 1.165 1.165 0 0 1-.119.014c-.863.024-3.816.01-4.021-.02 0-.035-.012-.073-.014-.112V6.742a.237.237 0 0 1 .168-.036h3.957a.176.176 0 0 1 .049.164V8.153a.472.472 0 0 0 .078-.057 4.525 4.525 0 0 1 2.547-1.663 6.407 6.407 0 0 1 2.109-.2 3.723 3.723 0 0 1 2.363.987 4.461 4.461 0 0 1 .951 1.3 7.04 7.04 0 0 1 .6 1.865 10.139 10.139 0 0 1 .158 1.835v7.542a.643.643 0 0 1-.008.2c-.02.023-.023.029-.029.033a.032.032 0 0 1-.018.005c-.061 0-.123.007-.184.007ZM.377 19.919a.259.259 0 0 1-.02-.062V6.782a.2.2 0 0 1 .012-.048.129.129 0 0 1 .021-.021.048.048 0 0 1 .02-.005c.047 0 .094-.006.141-.006h3.912a.212.212 0 0 1 .051.017c0 .032.014.07.016.108v13.082a1.219 1.219 0 0 1-.125.021H.51a.408.408 0 0 1-.135-.014ZM2.434 4.883a2.449 2.449 0 1 1 .008 0Z" />
  </svg>
)

const Memo = memo(SvgLinkedin)
export default Memo
