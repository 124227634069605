import * as React from 'react'
import { memo } from 'react'

const SvgPlaylist = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
  >
    <path d="M0 18a18 18 0 1 1 18 18A18.019 18.019 0 0 1 0 18Zm1.44 0A16.56 16.56 0 1 0 18 1.44 16.577 16.577 0 0 0 1.44 18Zm2.879 0a.721.721 0 0 1 1.441 0A12.252 12.252 0 0 0 18 30.24a.72.72 0 1 1 0 1.441A13.7 13.7 0 0 1 4.319 18Zm4.321 0a.72.72 0 1 1 1.44 0A7.928 7.928 0 0 0 18 25.92a.72.72 0 1 1 0 1.441A9.372 9.372 0 0 1 8.64 18Zm5.76 0a3.6 3.6 0 1 1 3.6 3.6 3.6 3.6 0 0 1-3.6-3.6Zm1.44 0A2.159 2.159 0 1 0 18 15.841 2.162 2.162 0 0 0 15.84 18Zm14.029-.206a12.253 12.253 0 0 0-12.24-12.24.72.72 0 0 1 0-1.441A13.7 13.7 0 0 1 31.31 17.794a.721.721 0 0 1-1.441 0Zm-4.319 0a7.93 7.93 0 0 0-7.921-7.92.72.72 0 0 1 0-1.441 9.372 9.372 0 0 1 9.36 9.36.72.72 0 1 1-1.44 0Z" />
  </svg>
)

const Memo = memo(SvgPlaylist)
export default Memo
