import * as React from 'react'
import { memo } from 'react'
const SvgWhatsapp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path d="M8.15 0A7.824 7.824 0 0 0 .295 7.794a7.713 7.713 0 0 0 1.127 4.025L0 16l4.35-1.381A7.868 7.868 0 0 0 16 7.794 7.824 7.824 0 0 0 8.15 0Zm3.9 10.75a2.029 2.029 0 0 1-1.389.9c-.368.02-.379.286-2.387-.587a8.2 8.2 0 0 1-3.311-3.131 3.852 3.852 0 0 1-.741-2.088 2.226 2.226 0 0 1 .764-1.633.769.769 0 0 1 .544-.229h.378c.117 0 .293-.024.445.38s.516 1.4.562 1.5a.364.364 0 0 1 0 .349 1.364 1.364 0 0 1-.213.324c-.1.112-.22.252-.314.337s-.213.2-.1.4A6.011 6.011 0 0 0 7.35 8.693 5.48 5.48 0 0 0 8.925 9.74c.2.107.314.1.437-.033s.523-.562.664-.756.274-.156.455-.083 1.148.591 1.345.7.328.162.375.246a1.649 1.649 0 0 1-.151.936Z" />
  </svg>
)
const Memo = memo(SvgWhatsapp)
export default Memo
