import * as React from 'react'
import { memo } from 'react'

const SvgFile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 14.789 18"
  >
    <path d="M8.921.235A.847.847 0 0 0 8.337 0H2.543A2.541 2.541 0 0 0 .002 2.541v12.918A2.541 2.541 0 0 0 2.543 18h9.707a2.541 2.541 0 0 0 2.541-2.541V6.194a.847.847 0 0 0-.262-.612Zm.263 2.592 2.645 2.52H9.184Zm3.064 13.479H2.543a.847.847 0 0 1-.847-.847V2.541a.847.847 0 0 1 .847-.847h4.949v3.653a1.694 1.694 0 0 0 1.694 1.694h3.911v8.418a.847.847 0 0 1-.847.847Z" />
  </svg>
)

const Memo = memo(SvgFile)
export default Memo
