import * as React from 'react'
import { memo } from 'react'

const SvgPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 9.397 12"
  >
    <path d="M4.7 0A4.63 4.63 0 0 0 0 4.542a4.447 4.447 0 0 0 .94 2.714l3.55 4.646a.281.281 0 0 0 .365.052l.052-.052 3.55-4.646a4.447 4.447 0 0 0 .94-2.714A4.63 4.63 0 0 0 4.697 0Zm-.365 6.316a1.822 1.822 0 1 1 2.14-2.14 1.84 1.84 0 0 1-1.436 2.14 1.606 1.606 0 0 1-.706 0Z" />
  </svg>
)

const Memo = memo(SvgPin)
export default Memo
